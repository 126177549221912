import React from "react"
import { StoryGroup } from "components"
import { getEBooksCoverUrl } from "utils/rsc-util"

const eBooks = [
  {
    title: "Mastering React Client State Management",
    url:
      "https://learnreactui.dev/contents/mastering-react-client-state-management",
    img: getEBooksCoverUrl("019_client-state-management"),
  },
  {
    title: "Zustand İle State Yönetimi",
    url: "https://learnreactui.dev/contents/zustand-ile-state-yonetimi",
    img: getEBooksCoverUrl("041-zustand-ile-state-yonetimi"),
  },
  {
    title: "Zustand Re-Render Example",
    url: "https://learnreactui.dev/contents/zustand-rerender",
    img: getEBooksCoverUrl("072-zustand-rerender"),
  },
  {
    title: "React Query 1 (Temel Bilgiler)",
    url: "https://learnreactui.dev/contents/react-query-1-temel-bilgiler",
    img: getEBooksCoverUrl("045-react-query1"),
  },
]

const LinkMap = {
  Flux: "a9b2198e3cfa",
  Store: "a9b2198e3cfa",
  Dispatcher: "a9b2198e3cfa",
  View: "a9b2198e3cfa",

  "Redux-Thunk": "15ec4dde2dc0",
  "Redux-Saga": "15ec4dde2dc0",

  "Lifting StateUp": "756125ba62db",
  "Compose Components": "756125ba62db",
  Redux: "756125ba62db",

  "Redux-Thunk2": "7e6b25611674",
  "Redux-Promise": "7e6b25611674",

  "State Reconciler": "613cefac7d9a",
  "Migration or Transform": "613cefac7d9a",
  autoMergeLevel2: "613cefac7d9a",

  "React Context": "f37212e671ee",
  "React useReducer": "f37212e671ee",

  "React State Manage": "8d81ad5689d0",
  "proxy-memoize": "8d81ad5689d0",
  Reselect: "8d81ad5689d0",
  "Re-reselect": "8d81ad5689d0",
  "Redux-Views": "8d81ad5689d0",
  "Apollo Client": "8d81ad5689d0",
  GraphQL: "8d81ad5689d0",

  "Global State Management": "c34f6844d6d1",
  "Server State Manamagent": "c34f6844d6d1",
  "Client State Management": "c34f6844d6d1",
  "RTK Query": "c34f6844d6d1",
  "React Query": "c34f6844d6d1",
  SWR: "c34f6844d6d1",
  "Apollo GraphQL Client": "c34f6844d6d1",

  "Provider Pattern": "4836589946a3",
  "Props Drilling": "66ec42efa00d",
  useState: "66ec42efa00d",
  "React Context2": "73fae473971d",
  Zustand: "d17d30580d1b",
  Jotai: "d79e7011a740",
  Signals: "b515762e4d8",
}

const reduxStateManagement = [
  {
    title: "Flux Patten (Redux, vb...)",
    postId: "a9b2198e3cfa",
  },
  {
    title: "Redux-Thunk ve Redux-Saga",
    postId: "15ec4dde2dc0",
  },
  {
    title: "React Virtual DOM 2 Uzak Bileşenin İletişimi",
    postId: "756125ba62db",
  },
  {
    title: "React ile Rest API Cağırımı",
    postId: "7e6b25611674",
  },
]

const reactStateManagement = [
  {
    title: "React State Management Yöntemleri",
    postId: "f37212e671ee",
  },
  {
    title: "React State Management Yöntemleri-2",
    postId: "8d81ad5689d0",
  },
  {
    title: "React State Management Yöntemleri-3",
    postId: "c34f6844d6d1",
  },
]

const patternsStateManagement = [
  {
    title: "Provider Pattern Kullanımı",
    postId: "4836589946a3",
    postIdEnglish: "cf22358aff9c",
  },
]

const clientStateManagement = [
  {
    title: "React State",
    postId: "66ec42efa00d",
  },
  {
    title: "React Context",
    postId: "73fae473971d",
  },
  {
    title: "Zustand",
    postId: "d17d30580d1b",
  },
  {
    title: "Jotai",
    postId: "d79e7011a740",
  },
  {
    title: "Redux",
    postId: "bd00fadfef3a",
  },
  {
    title: "Signals",
    postId: "b515762e4d8",
  },
]

const zustand = [
  {
    title: "Zustand",
    postId: "d17d30580d1b",
  },
  {
    title: "Zustand, Redux Yerine Kullanılabilir mi — 1?",
    postId: "351fe0985092",
  },
  {
    title: "Zustand, Middleware— 2?",
    postId: "72c395a9b579",
  },
  {
    title: "Zustand, DevTool Integration",
    postId: "4262c09ce22f",
  },
  {
    title: "Zustand Re-Render Use Case",
    postId: "d97a58188625",
  },
  {
    title: "Zustand Re-Render Use Case2",
    postId: "853f01ede4e8",
  },
]

const reactQuery = [
  {
    title: "React Query",
    postId: "fc266da9f7fa",
  },
  {
    title: "React Query (Axios & GraphQL)",
    postId: "141792746a44",
  },
  {
    title: "React Query 4 -> 5 Migration",
    postId: "d5202abca2c0",
  },
  {
    title: "React Query Architecture",
    postId: "2d8f1c6b33ce",
  },
  {
    title: "Optimistic Update",
    postId: "efd7ece4c51b",
  },
]

const storiesTips = [
  {
    title: "How to fix Redux-Persist Confliction",
    postId: "613cefac7d9a",
  },
  {
    title: "How to Reset Redux Store",
    postId: "5ccd4791f16e",
  },
]

const storiesGroup = [
  {
    title: "Redux",
    storyPart: reduxStateManagement,
  },
  {
    title: "State Management",
    storyPart: reactStateManagement,
  },
  {
    title: "Patterns",
    storyPart: patternsStateManagement,
  },
  {
    title: "Client State Management",
    storyPart: clientStateManagement,
  },
  {
    title: "Zustand",
    storyPart: zustand,
  },
  {
    title: "React Query",
    storyPart: reactQuery,
  },
  {
    title: "Tips",
    storyPart: storiesTips,
  },
]

export const context = {
  stories: storiesGroup,
  linkMap: LinkMap,
  title: "React State",
  path: "react-state",
}

const ReactStatePage = () => {
  return (
    <StoryGroup
      title={context.title}
      eBooks={eBooks}
      complex={true}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactStatePage
